import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { CodigoNotaria, UrlApi } from "../../config.js";
import {cargarNotaria} from '../../actions/notariaAction'


// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Button,
  FormGroup,
  Input,
  Table,
} from "reactstrap";

import * as jsPDF from 'jspdf'
import logo from "assets/img/notarias/logo27.png";

function CotizadorW() {

  const dispatch = useDispatch()
  
  const [tipoEscritura, setTipoEscritura] = React.useState("");
  const [nombreActo, setNomreActo] = React.useState("");
  const [valorCuantia, setValorCuantia] = React.useState("");
  const [valorHipoteca, setValorHipoteca] = React.useState("");
  const [tDerechos, setTotalDerechos] = React.useState(0);
  const [tCotizacion, setCotizacion] = React.useState(0);
  const [tFondo, setFondo] = React.useState(0);
  const [tSuper, setSuper] = React.useState(0);
  const [tRete, setRete] = React.useState(0);
  const [tIva, setIva] = React.useState(0);
  const [showHipoteca, setHipoteca] = React.useState(false);
  const [showCuantía, setCuantia] = React.useState(false);
  const [showDerechos, setDerechos] = React.useState(false);
  const [showStatico, setStatico] = React.useState(false);
  const [servicios, setServicios] = React.useState([]);
  const [checkedRete, setCheckedRete] = React.useState(true);
  const [service_disabled, setServiceDisabled] = React.useState([
    1,
    10,
    11,
    12,
  ]);

  const notaria = useSelector((state) => state.notaria.notaria);
  React.useEffect(() => {
    
    const consultar = async () =>{
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi+'notaria',config)
      if(resultado.status === 200){
        let dataTemp ={}        
        resultado.data.forEach((element)=>{
          dataTemp[element.variable]=element.value
        })
        dispatch(cargarNotaria(dataTemp))
      }
   }
   consultar()
  },[]);

  React.useEffect(() => {
    const consultarServicios = async () => {
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }
      const resultado = await Axios.get(
        process.env.REACT_APP_UrlApi + "configCotizador",config
      );

      const options = resultado.data;
      
      setServicios(options);
    };
    consultarServicios();
  }, []);

  const updateValues = (id, value) => {
    let tempService = servicios;
    servicios.forEach((item, index) => {
      if (item.id.toString() === id.toString()) {
        tempService[index].valor_unit = value;
      }
    });
    setServicios(tempService);
    updateTable();
  };

  const updateCantidad = (id, value) => {
    let tempService = servicios;
    servicios.forEach((item, index) => {
      if (item.id.toString() === id.toString()) {
        tempService[index].cant_predet = value;
      }
    });
    setServicios(tempService);
    updateTable();
  };

  const updateTable = () => {
    var totalCotizacion = 0;

    servicios.forEach((item) => {
      totalCotizacion += item.valor_unit * item.cant_predet;
    });

    let derechosConfig = JSON.parse(notaria.derechos_notariales)
    let fondo = 0;
    fondo =
    valorCuantia <= 100000000
      ? derechosConfig.valorCuantiaMenor100M
      : valorCuantia <= 300000000
      ? derechosConfig.valorCuantiaMenor300M
      : valorCuantia <= 500000000
      ? derechosConfig.valorCuantiaMenor500M
      : valorCuantia <= 1000000000
      ? derechosConfig.valorCuantiaMenor1000M
      : valorCuantia <= 1500000000
      ? derechosConfig.valorCuantiaMenor1500M
      : derechosConfig.valorCuantiaMayor150M;

      setFondo(fondo);
      setSuper(fondo);
      setIva(Math.round(totalCotizacion * derechosConfig.iva/100));
      setRete(Math.round(valorCuantia * derechosConfig.rete/100));
      setCotizacion(
        totalCotizacion +
          fondo +
          fondo +
          Math.round(valorCuantia * derechosConfig.rete/100) +
          Math.round(totalCotizacion *derechosConfig.iva/100)
      );
  };

  const selectTipoEscritura = (event) => {
    setTipoEscritura(event.target.value);
    switch (event.target.value) {
      case "1": //compraventa
        setHipoteca(false);
        setCuantia(true);
        break;
      case "2": // //compraventa e hipoteca con particular
        setHipoteca(true);
        setCuantia(true);
        break;
      case "3": //compraventa e hipoteca con entidad financiera
        setHipoteca(true);
        setCuantia(true);
        break;
      case "4": //hipotecas
        setHipoteca(true);
        setCuantia(false);
        break;
      case "5": //Cancelación hipoteca con particular
        setHipoteca(true);
        setCuantia(false);
        break;
      case "6": //cancelación hipoteca con entidad financiera
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(66200);
        break;
      case "7": //Matrimonio Civil
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(47400);
        break;
      case "8": //Matrimonio a domicilio
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(127900);
        break;
      case "9": //Sucesiones
        setHipoteca(false);
        setCuantia(true);
        break;
      case "10": //Liquidación de sociedad conyugal
        setHipoteca(false);
        setCuantia(true);
        break;
      default :
    }

    setDerechos(true);
  };

  const CheckedRetencion = (val) => {
    var totalCotizacion = 0;

    servicios.forEach((item) => {
      totalCotizacion += item.valor_unit * item.cant_predet;
    });

    setCheckedRete(val);
    if (val) {
      setCotizacion(totalCotizacion + tFondo + tSuper + tRete + tIva);
    } else {
      setCotizacion(totalCotizacion + tFondo + tSuper + tIva);
    }
  };

  const cotizar = () => {
    let derechosConfig = JSON.parse(notaria.derechos_notariales)
    let tope = derechosConfig.tope;
    let topeSucesiones = derechosConfig.topeSucesiones
    let subtotal = 0;
    let total = 0;
    let base = derechosConfig.base;
    let baseActosSinCuantia = derechosConfig.baseActosSinCuantia;
    let incremento = derechosConfig.incremento/100;
    switch (tipoEscritura) {
      case "1": //compraventa
        if (valorCuantia > tope) {
          subtotal = valorCuantia - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Compraventa");
        break;
      case "2": // //compraventa e hipoteca con particular
        if (valorCuantia > tope) {
          subtotal = (valorCuantia - tope) * incremento + base;
        } else {
          subtotal = base;
        }
        if (valorHipoteca > tope) {
          subtotal = (valorHipoteca - tope) * incremento + base + subtotal;
        } else {
          subtotal = subtotal + base;
        }
        total = subtotal;
        setNomreActo("Compraventa e hipoteca con particular");
        break;
      case "3": //compraventa e hipoteca con entidad financiera
        if (valorCuantia > tope) {
          subtotal = (valorCuantia - tope) * incremento + base;
        } else {
          subtotal = base;
        }

        if (valorHipoteca > tope) {
          subtotal =
            ((valorHipoteca - tope) * incremento + base) * 0.7 + subtotal;
        } else {
          subtotal = subtotal + base * 0.7;
        }
        total = subtotal;
        setNomreActo("Compraventa e hipoteca con entidad financiera");
        break;
      case "4": //hipotecas
        if (valorHipoteca + valorCuantia > tope) {
          subtotal = valorCuantia + valorHipoteca - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Hipoteca");
        break;
      case "5": //Cancelación hipoteca con particular
        if (valorHipoteca > tope) {
          subtotal = valorHipoteca - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Cancelación hipoteca con particular");
        break;
      case "6": //cancelación hipoteca con entidad financiera
        total = 66200;
        setNomreActo("Cancelación hipoteca con entidad financiera");
        break;
      case "7": //Matrimonio Civil
        total = 47400;
        setNomreActo("Matrimonio Civil");
        break;
      case "8": //Matrimonio a domicilio
        total = 127900;
        setNomreActo("Matrimonio a domicilio");
        break;
      case "9": //Sucesiones
        if (valorCuantia > tope) {
          subtotal = valorCuantia - tope;
          total = subtotal * 0.0035 + base;
        } else {
          total = base;
        }
        setNomreActo("Sucesiones");
        break;
      case "10": //Liquidación de sociedad conyugal
        if (valorCuantia > tope) {
          subtotal = valorCuantia - tope;
          total = subtotal * 0.0035 + base;
        } else {
          total = base;
        }
        setNomreActo("Liquidación de sociedad conyugal");
        break;
      default:
        total = 0;
        break;
    }

    let tipoEscrituraEspecial = ["6", "7", "8"];
    total = Math.round(total);

    if (tipoEscrituraEspecial.indexOf(tipoEscritura) !== -1) {
      setStatico(false);
      return;
    }
    setStatico(true);
    setTotalDerechos(total);
    updateValues(1, total);
    updateTable();
  };

  const formatear = (num) => {
    while (num.toString().indexOf(".") !== -1) {
      num = num.toString().replace(".", "");
    }
    num = num.toString();
    let resultado = "";
    for (var j, i = num.length - 1, j = 0; i >= 0; i--, j++)
      resultado = num.charAt(i) + (j > 0 && j % 3 === 0 ? "." : "") + resultado;

    return resultado;
  };

  const getDataUri = (url) => {
    return new Promise((resolve) => {
      var image = new Image();

      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        //next three lines for white background in case png has a transparent background
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#fff"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext("2d").drawImage(this, 0, 0);

        resolve(canvas.toDataURL("image/jpeg"));
      };

      image.src = url;
    });
  };

  const pdf = async () => {
    let vertical = 0;
    let horizontal = 0;
    var doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "letter",
    });
    doc.setFontSize(16);
    doc.setFontType("bold");
    doc.text(notaria.nombre_notaria, 20, 25);
    doc.setFontSize(10);
    vertical = 30;
    horizontal = 20;
    doc.setFontType("normal");
    doc.text(notaria.notario, horizontal, vertical);
    doc.text("Nit. " + notaria.identificacion, horizontal, vertical + 5);
    doc.text(notaria.direccion, horizontal, vertical + 10);
    doc.text(notaria.telefono, horizontal, vertical + 15);
    doc.text(notaria.regimen, horizontal, vertical + 20);
    doc.line(horizontal, vertical + 23, 200, vertical + 23);
    doc.line(horizontal, vertical + 24, 200, vertical + 24);

    var urlLogo = await getDataUri(logo);
    doc.addImage(urlLogo, "JPEG", 130, 20, 60, 25);

    vertical = 60;
    doc.setFontSize(16);
    doc.setFontType("bold");
    doc.text("Cotización", 100, vertical);
    doc.setFontSize(12);
    doc.setFontType("normal");
    doc.text("Acto:", horizontal + 10, vertical + 10);
    doc.text(nombreActo, horizontal + 23, vertical + 10);
    var f = new Date();
    doc.text(
      "Fecha: " +
        f.getDate() +
        "/" +
        (f.getMonth() + 1) +
        "/" +
        f.getFullYear(),
      horizontal + 130,
      vertical + 10
    );
    if (showCuantía) {
      doc.text("Cuantía:", horizontal + 35, vertical + 20);
      doc.text("$" + formatear(valorCuantia), horizontal + 55, vertical + 20);
    }
    if (showHipoteca) {
      doc.text("Hipoteca:", horizontal + 95, vertical + 20);
      doc.text("$" + formatear(valorHipoteca), horizontal + 115, vertical + 20);
    }

    doc.setFontType("bold");
    vertical = 90;
    horizontal = 10;
    doc.text("Servicio", horizontal + 20, vertical);
    doc.text("Cantidad", horizontal + 80, vertical, "center");
    doc.text("Precio. Uni", horizontal + 110, vertical);
    doc.text("Total", horizontal + 156, vertical);

    doc.setFontType("normal");
    vertical = 100;
    horizontal = 30;

    {
      servicios.map((item) => {
        doc.text(item.servicio_name, horizontal, vertical);
        doc.text(String(item.cant_predet), horizontal + 60, vertical);
        doc.text(
          "$" + formatear(item.valor_unit),
          horizontal + 110,
          vertical,
          "right"
        );
        doc.text(
          "$" + formatear(item.valor_unit * item.cant_predet),
          horizontal + 150,
          vertical,
          "right"
        );
        vertical += 5;
      });
    }

    vertical += 10;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Iva:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tIva), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Super:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tSuper), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Fondo:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tFondo), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    if (checkedRete) {
      doc.text("Retención:", horizontal + 110, vertical, "right");
      doc.text("$" + formatear(tRete), horizontal + 150, vertical, "right");
    }

    vertical += 5;
    doc.setFontType("bold");
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Gran Total:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tCotizacion), horizontal + 150, vertical, "right");
    doc.setFontType("normal");

    vertical = 200;
    doc.line(20, vertical, 200, vertical);
    doc.setFontSize(10);
    doc.text("Generado por Sinfony", 85, vertical + 5);
    doc.save("Cotizacion.pdf");
  };

  return (
    <>
      <div id="cotizar" style={{ backgroundColor: "White" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h1 className="text-center title" style={{ color: "#031425" }}>
                COTIZADOR<br></br>
              </h1>
              <div className="space-25"></div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" lg="6">
              <Card style={{borderRadius: "15px", border: "1px solid #888888"}}>
                <CardHeader style={{ borderBottom: "1px solid #888888"}}>
                  <h2 className="info-title text-center" style={{marginTop: "10px"}}><b>Acto a cotizar</b></h2>
                </CardHeader>
                <CardBody style={{marginBottom: "20px"}}>
                    <FormGroup>
                      <label htmlFor="Select1"><h4 className="info-title text-center">Seleccionar:</h4></label>
                      <Input id="Select1" type="select" bsSize="lg" value={tipoEscritura} onChange={selectTipoEscritura}> 
                        <option className="disabled" style={{color: "#a8a6a6", fontSize: "16px", marginBottom: "10px"}}>Elija el tipo de acto:</option>
                        <option value = "1" style={{fontSize: "14px", marginBottom: "5px"}}>Compra Venta</option>
                        <option value = "2" style={{fontSize: "14px", marginBottom: "5px"}}>Compra Venta e hipoteca con particulares</option>
                        <option value = "3" style={{fontSize: "14px", marginBottom: "5px"}}>Compra Venta e Hipoteca con entidades financieras</option>
                        <option value = "4" style={{fontSize: "14px", marginBottom: "5px"}}> Hipoteca</option>
                        <option value = "5" style={{fontSize: "14px", marginBottom: "5px"}}>Cancelación de hipoteca con particulares</option>
                        <option value = "6" style={{fontSize: "14px", marginBottom: "5px"}}>Cancelación de hipoteca con entidades financieras</option>
                        <option value = "7" style={{fontSize: "14px", marginBottom: "5px"}}>Matrimonio Civil en la notaría</option>
                        <option value = "8" style={{fontSize: "14px", marginBottom: "5px"}}>Matrimonio Civil a domicilio</option>
                        <option value = "9" style={{fontSize: "14px", marginBottom: "5px"}}>Sucesiones</option>
                        <option value = "10" style={{fontSize: "14px", marginBottom: "5px"}}>Liquidación de sociedad conyugal</option>
                      </Input>
                    </FormGroup>
                </CardBody>
              </Card>
            </Col>
            {showDerechos && 
            <Col className="ml-auto mr-auto" lg="6">
              <Card className="text-center" style={{borderRadius: "15px", border: "1px solid #888888"}}>
                <CardHeader>
                  <h4 className="info-title text-center" style={{marginTop: "5px"}}>Ingresar el valor:</h4>
                </CardHeader>
                  <CardBody>
                    <Form> 
                      <Row className="justify-content-center">             
                        {showCuantía && (
                            <Col sm="6">
                              <FormGroup>
                                <label><b>Cuantía</b></label>
                                <Input
                                  id="valor_cuantia"
                                  onChange ={(e) => setValorCuantia(e.target.value)}
                                  type= "number"
                                >
                                </Input>
                                <br></br>
                                {showCuantía && ( 
                                  <label className="text-center"><b>Valor Cuantía: $ </b>{formatear(valorCuantia)}</label>
                                )}                                                         
                              </FormGroup>     
                            </Col>
                          )
                        }      
                      {showHipoteca && (
                          <Col sm="6">
                            <FormGroup>
                              <label><b>Hipoteca</b></label>
                              <Input
                                id="valor_hipoteca"
                                onChange ={(e) => setValorHipoteca(e.target.value)}
                                type="number"
                              >
                              </Input>
                              <br></br>
                              {showHipoteca && ( 
                                <label className="text-center"><b>Valor Hipoteca: $ </b>{formatear(valorHipoteca)}</label>
                              )}                                                         
                            </FormGroup>     
                          </Col>
                        )
                      }
                      </Row>
                    </Form>
                    {(showCuantía || showHipoteca || showStatico) && (
                      <Button onClick={cotizar} color="info"><b style={{fontSize: "14px"}}>Cotizar</b></Button>
                  )}   
                </CardBody>
              </Card>
            </Col>
            }
          </Row>
          <Row>
            {showStatico && (
            <Col lg="12">
              <Card id="pdfdiv" style={{borderRadius: "15px", border: "1px solid #888888"}}>
                  <CardHeader>
                     <h2 className="info-title text-center" style={{marginTop: "20px"}}><b>Cotización</b></h2>
                  </CardHeader>
                  <CardBody>
                      <Table className="text-center" responsive>
                        <thead>
                          <th style={{color:"#1c6e38"}}>Servicios</th>
                          <th style={{color:"#1c6e38"}}>Cantidad</th>
                          <th style={{color:"#1c6e38"}}>Precio Unitario</th>
                          <th style={{color:"#1c6e38"}}>Total</th>
                        </thead>
                        <tbody>
                          {servicios.map(item => (
                            <tr key={uuidv4()}>
                                <td>{item.servicio_name}</td>
                                <td>
                                  <Row className="justify-content-center">
                                    <Col sm="6">
                                      <FormGroup>
                                        <Input
                                          id={item.id.toString()}
                                          disabled = {service_disabled.includes(item.id)}
                                          defaultValue = {item.cant_predet}
                                          onChange ={(e) => updateCantidad(e.target.id,e.target.value)}
                                          type="number"
                                        >
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </td>
                                <td>
                                  {"$"+formatear(item.valor_unit)}
                                </td>
                                <td>
                                  {"$"+formatear(item.valor_unit*item.cant_predet)}
                                </td>
                            </tr>
                          ))}
                          <tr>
                            <td></td>
                            <td></td>
                            <td>IVA</td>
                            <td>{"$"+formatear(tIva)}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>Super</td>
                            <td>{"$"+formatear(tFondo)}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <Input 
                                type="switch" 
                                id="exampleCustomSwitch" 
                                name="customSwitch" 
                                label="SI (Retención)" 
                                checked={checkedRete}
                                onChange={event => CheckedRetencion(event.target.checked)}
                                value="checkedA"
                              />
                            </td>
                            <td>{(checkedRete && "$"+formatear(tRete))}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>Total Cotización</td>
                            <td>{"$"+formatear(tCotizacion)}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Row>
                        <Col sm="12" className="text-center">
                            <h5 className="info-title text-center" style={{marginTop: "10px"}}><b>Nota: </b><i>Retención <b>SI</b> para Persona Natural y <b>NO</b> para Persona Jurídica.</i></h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="text-center">
                          <Button onClick={pdf} color="info"><b style={{fontSize: "14px"}}>Imprimir</b></Button>
                        </Col>
                      </Row>
                      {!showStatico && (
                      <h3><b>Total: ${formatear(tCotizacion)}</b></h3>
                      )}
                  </CardBody>
              </Card>
            </Col>
          )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CotizadorW;
