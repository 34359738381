/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col,Card,CardBody,CardTitle } from "reactstrap";
// core components

function PresentationHeader() {
  return (
    <>
    <Container fluid>
      <Row>
          <Card
            className="card-fashion page-header-image"
            style={{
              backgroundSize: "100% 100%",
              backgroundImage:
                "url(" + require("assets/img/notarias/bg0.jpg") + ")",
              height: "650px"
            }}
          >
            <CardBody>
              <CardTitle className="text-left" tag="div">
              <div className="rellax-text-container">
                <h1 className="h1-seo" data-rellax-speed="-1" style={{marginTop: "-400px"}}>
                  Notaría 27
                </h1>
                <div className="pro">Bogotá</div>
                <h2 className="title" data-rellax-speed="-1">
                SERVICIOS NOTARIALES
                </h2>
              </div>
              </CardTitle>
            </CardBody>
          </Card>
      </Row>
     </Container>
      {/* <div className="page-header page-header-small" >
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/notarias/bg0.jpg") +
              ")",
          }}
        >
          <div className="rellax-text-container">
            <h2 className="h1-seo" data-rellax-speed="-1">
              Notaría 27
            </h2>
            <div className="pro">Bogotá</div>
            <h3 className="title" data-rellax-speed="-1">
            SERVICIOS NOTARIALES
            </h3>
           </div>
        </div>
      </div> */}
    </>
  );
}

export default PresentationHeader;
